/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  --hue: 250;
  --sat: 66%;
  --lig: 75%;
  --first-color: hsl(var(--hue), var(--sat), var(--lig));
  --first-color-alt: hsl(var(--hue), var(--sat), 71%); /* -4% */
  --title-color: hsl(var(--hue), 12%, 15%);
  --text-color: hsl(var(--hue), 12%, 45%);
  --text-color-light: hsl(var(--hue), 8%, 75%);
  --text-color-lighten: hsl(var(--hue), 8%, 92%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #fff;
  --button-color: #fff;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 968px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: -2.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*=============== PROFILE ===============*/
.profile {
  position: relative;
  padding-top: 3.5rem;
}

.profile__container {
  row-gap: 2rem;
}

.profile__data {
  display: grid;
  text-align: center;
}

.profile__fill {
  width: 180px;
  height: 180px;
  background: linear-gradient(
    100deg,
    hsla(var(--hue), var(--sat), var(--lig), 1) 0%,
    hsla(var(--hue), var(--sat), var(--lig), 0.2) 100%
  );
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.profile__fill img {
  width: 180px;
}

.profile__border {
  /* border: 3px solid var(--first-color); */
  justify-self: center;
  width: 195px;
  height: 195px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 0.75rem;
}

.profile__name {
  font-size: var(--h2-font-size);
}

.profile__profession {
  font-size: var(--small-font-size);
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.profile__social {
  display: flex;
  justify-content: center;
  column-gap: 0.75rem;
}

.profile__social_link {
  font-size: 1.25rem;
  transition: 0.3s;
}

/* .profile__social_link:hover {
  color: var(--first-color);
} */

.profile__info {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  column-gap: 2.5rem;
}

.profile__info_group {
  text-align: center;
}

.profile__info_number {
  font-size: var(--normal-font-size);
  margin-bottom: 0.25rem;
}

.profile__info_description {
  font-size: var(--smaller-font-size);
  font-weight: 500;
}

.profile__buttons,
.profile__buttons_small {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__buttons {
  column-gap: 1rem;
}

.profile__buttons_small {
  column-gap: 0.25rem;
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  /* background-color: var(--first-color); */
  color: #fff;
  padding: 1.15rem 1.5rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  box-shadow: 0 8px 24px hsla(var(hue), var(--sat), var(--lig), 0.25);
}

.button i {
  font-size: 1.25rem;
}

/* .button:hover {
  background-color: var(--first-color-alt);
} */

.button__small {
  padding: 0.75rem;
  box-shadow: none;
}

.button__gray {
  background-color: var(--text-color-lighten);
  color: var(--title-color);
}

.button__gray:hover {
  background-color: var(--text-color-light);
}

/*=============== SKILLS ===============*/
.skills__content {
  row-gap: 3.5rem;
}

.skills__title {
  font-size: var(--h3-font-size);
  text-align: center;
  margin-bottom: 1.5rem;
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 3rem;
}

.skills__group {
  display: grid;
  align-content: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills__data i {
  font-size: 1rem;
  /* color: var(--first-color); */
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: 500;
  line-height: 18px;
}

.skills__level {
  font-size: var(--smaller-font-size);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .profile__info {
    column-gap: 1.5rem;
  }

  .profile__buttons {
    flex-direction: column;
    row-gap: 1rem;
  }

  .skills__box {
    column-gap: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .skills__content {
    grid-template-columns: repeat(2, 332px);
  }

  .skills__content {
    justify-content: center;
    column-gap: 3rem;
  }

  .profile__border {
    width: 215px;
    height: 215px;
    margin-bottom: 1rem;
  }

  .profile__fill {
    width: 200px;
    height: 200px;
  }

  .profile__fill img {
    width: 200px;
  }
}

/* For large devices */
@media screen and (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .profile {
    padding-top: 4rem;
  }

  .profile__border {
    width: 235px;
    height: 235px;
    margin-bottom: 1rem;
  }

  .profile__fill {
    width: 220px;
    height: 220px;
  }

  .profile__fill img {
    width: 220px;
  }

  .profile__profession {
    margin-bottom: 1.5rem;
  }

  .profile__info {
    column-gap: 3rem;
  }

  .profile__info-description {
    font-size: var(--small-font-size);
  }

  .profile__buttons {
    column-gap: 2rem;
  }

  .skills__title {
    font-size: var(--normal-font-size);
    margin-bottom: 2.5rem;
  }
}
