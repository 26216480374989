.loadingScreen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #151320; */
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  perspective: 800px;
}

.loader .loader_item {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.loader .loader_item:nth-child(1) {
  border-bottom: 6px solid #f13a8f;
  transform: rotateX(35deg) rotateY(-45deg);
  animation: rotate-one 1s linear infinite;
}

.loader .loader_item:nth-child(2) {
  border-right: 6px solid #4bc8eb;
  transform: rotateX(50deg) rotateY(10deg);
  animation: rotate-two 1s linear infinite;
}

.loader .loader_item:nth-child(3) {
  border-top: 6px solid #36f372;
  transform: rotateX(35deg) rotateY(55deg);
  animation: rotate-three 1s linear infinite;
}

@keyframes rotate-one {
  to {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  to {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  to {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
